/* eslint-disable import/no-cycle */
import axios from 'axios';

import * as API from '../api';

import UserActionTypes from './UserActionTypes';
import URLS from '../../utils/constants/URLS';

import { initializePlanState } from '../plan/PlanActions';
import { initializeInboxState } from '../inbox/InboxAction';
import { initializeClientState, clientSignedUp } from '../clients/ClientAction';
import { initializeTrainerState } from '../trainer/TrainerAction';
import { initializeWorkoutState } from '../workout/WorkoutAction';
import { initializeCalenderState } from '../calendar/CalendarAction';
import { initializeExerciseState } from '../exercise/ExerciseAction';
import { initializeSendPlanState } from '../sendPlan/SendPlanActions';
import { initializeDashboardState } from '../dashboard/DashboardAction';
import { initializePaymentReducer, setPaymentPlan } from '../payments/PaymentAction';
import {
  initializeCustomerAgreementState,
  fetchCustomerAgreementData,
  updateCustomerAgreementData,
} from '../customerAgreement/CustomerAgreementAction';
import {
  registerUserSuccess,
  setCurrentForm,
  setUserDetails,
  initializeSignUpState,
  resendVerificationCodeStart,
} from '../signup/SignupAction';
import CONSTANTS from '../../utils/constants/CONSTANTS';

export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const setUserType = (type) => ({
  type: UserActionTypes.SET_USER_TYPE,
  payload: type,
});

export const setAlreadySignedUp = (flag) => ({
  type: UserActionTypes.SET_ALREADY_SIGNED_UP,
  payload: flag,
});

export const updateUserStart = () => ({
  type: UserActionTypes.UPDATE_USER_START,
});

export const updateUserSuccess = (user) => ({
  type: UserActionTypes.UPDATE_USER_SUCCESS,
  payload: user,
});

export const updateUserPasswordSuccess = () => ({
  type: UserActionTypes.UPDATE_USER_PASSWORD_SUCCESS,
});

export const updateUserFailure = (error) => ({
  type: UserActionTypes.UPDATE_USER_FAILURE,
  payload: error,
});

export const initializeUserSettingState = () => ({
  type: UserActionTypes.INITIALIZE_STATE,
});

export const setAccessAndRefreshToken = (newTokens) => ({
  type: UserActionTypes.SET_ACCESS_AND_REFRESH_TOKEN,
  payload: newTokens,
});

export const userLoginStart = () => ({
  type: UserActionTypes.USER_LOGIN_START,
});

export const userLoginSuccess = (user) => ({
  type: UserActionTypes.USER_LOGIN_SUCCESS,
  payload: user,
});

export const userLoginFailure = (error) => ({
  type: UserActionTypes.USER_LOGIN_FAILURE,
  payload: error,
});

export const setRememberMe = (state) => ({
  type: UserActionTypes.SET_REMEMBER_ME,
  payload: state,
});

export const setIsLoggingIn = (state) => ({
  type: UserActionTypes.SET_LOGIN_STATE,
  payload: state,
});

export const setShowPopup = (state) => ({
  type: UserActionTypes.SET_SHOW_POPUP,
  payload: state,
});

export const setPopupData = (state) => ({
  type: UserActionTypes.SET_POPUP_DATA,
  payload: state,
});

export const setBuyNewPlanState = (state) => ({
  type: UserActionTypes.SET_BUYING_NEW_PLAN_STATE,
  payload: state,
});

export const setSettingsTab = (tab) => ({
  type: UserActionTypes.SET_USER_SETTINGS_TAB,
  payload: tab,
});

export const fetchUserSessionDetailStart = () => ({
  type: UserActionTypes.FETCH_USER_SESSION_DETAIL_START,
});

export const fetchUserSessionDetailSuccess = (data) => ({
  type: UserActionTypes.FETCH_USER_SESSION_DETAIL_SUCCESS,
  payload: data,
});

export const fetchUserSessionDetailFailure = (error) => ({
  type: UserActionTypes.FETCH_USER_SESSION_DETAIL_FAILURE,
  payload: error,
});

export const completeProfileMessageSuccess = () => ({
  type: UserActionTypes.COMPLETE_PROFILE_MESSAGE_SUCCESS,
});

export const completeProfileMessageFailure = () => ({
  type: UserActionTypes.COMPLETE_PROFILE_MESSAGE_FAILURE,
});

export const cancelledSubscriptionForManualUser = () => ({
  type: UserActionTypes.CANCELLED_SUBSCRIPTION_FOR_MANUAL_USER,
});

export const setUserState = (data, form, hasAlreadySignedUp = false) => {
  return (dispatch) => {
    dispatch(registerUserSuccess(data));
    dispatch(setPaymentPlan([]));
    dispatch(setIsLoggingIn(false));
    dispatch(setAlreadySignedUp(hasAlreadySignedUp));
    dispatch(setCurrentForm(form));
  };
};

export const initializeAllReducers = () => {
  return (dispatch) => {
    dispatch(initializePlanState());
    dispatch(initializeInboxState());
    dispatch(initializeClientState());
    dispatch(initializeSignUpState());
    dispatch(initializeTrainerState());
    dispatch(initializeWorkoutState());
    dispatch(initializeSendPlanState());
    dispatch(initializeExerciseState());
    dispatch(initializeCalenderState());
    dispatch(initializeDashboardState());
    dispatch(initializePaymentReducer());
  };
};

export const logOut = () => {
  return (dispatch) => {
    dispatch(initializeAllReducers());
    dispatch(setCurrentUser(null));
    dispatch(setUserType(null));
    dispatch(updateCustomerAgreementData(null));
  };
};

export const userLoginAsync = (user, setErrorMessage, setRoute) => {
  return (dispatch) => {
    setErrorMessage(null);
    dispatch(initializeAllReducers());
    dispatch(userLoginStart());
    dispatch(setIsLoggingIn(true));
    axios
      .post(URLS.login, user)
      .then((response) => {
        if (response.status === 201) {
          const { data } = response;
          if (data.user_type === CONSTANTS.CLIENT) {
            if (
              data.profile.is_email_verified &&
              data.profile.is_profile_complete &&
              data.profile.is_payment_complete
            ) {
              dispatch(initializeSignUpState());
              dispatch(initializeCustomerAgreementState());
              dispatch(userLoginSuccess(data));
              dispatch(fetchCustomerAgreementData());
              dispatch(completeProfileMessageFailure());
            } else if (data.profile.is_manual_user && !data.profile.is_profile_complete) {
              dispatch(completeProfileMessageSuccess());
              setRoute('/complete-profile');
            } else if (!data.profile.is_email_verified) {
              dispatch(setUserState(data, CONSTANTS.VERIFY_EMAIL));
              dispatch(setUserDetails({}));
              setRoute('/signup');
              dispatch(resendVerificationCodeStart());
            } else if (!data.profile.is_payment_complete) {
              dispatch(setUserState(data, CONSTANTS.PROGRAM_TYPES));
              const { weight, height, sex, goal, date_of_birth: dob, goal_weight: goalWeight } = data.profile;
              dispatch(
                setUserDetails({
                  date_of_birth: dob,
                  goal_weight: goalWeight,
                  goal,
                  height,
                  weight,
                  sex,
                })
              );
              setRoute('/signup');
            } else if (!data.profile.is_profile_complete) {
              dispatch(
                setUserDetails({
                  date_of_birth: data.profile.date_of_birth,
                  equipment_availability: null,
                  goal: null,
                  goal_weight: null,
                  height: null,
                  heightUnitSelected: null,
                  ideal_time: data.profile.ideal_time,
                  is_profile_complete: false,
                  program_type: data.profile.program_type,
                  session_days: data.profile.session_days,
                  session_time: data.profile.session_time,
                  sessions_per_week: data.profile.sessions_per_week,
                  sex: null,
                  weight: null,
                  weightUnitSelected: null,
                })
              );
              dispatch(setCurrentUser(data));
              const hasAlreadySignedUp = true;
              dispatch(setUserState(data, CONSTANTS.GENDER_INFO, hasAlreadySignedUp));
              setRoute('/signup');
            }
          } else {
            dispatch(userLoginSuccess(data));
            dispatch(fetchCustomerAgreementData());
          }
        }
      })
      .catch((error) => {
        dispatch(userLoginFailure(error?.response?.data?.detail));

        setErrorMessage(error?.response?.data?.detail || 'something went wrong, please try again later');
      });
  };
};

export const fetchUserSessionsDetailStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchUserSessionDetailStart());
    API.get(URLS.userSessionsDetail)
      .then((response) => {
        dispatch(fetchUserSessionDetailSuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchUserSessionDetailFailure('fetching sessions detail failed'));
      });
  };
};

export const updateUserAsync = (data, isSignUp, shouldLogin) => {
  return (dispatch, getState) => {
    const { currentUser } = getState().user;
    let accessToken = getState().user.currentUser?.access_token;
    if (isSignUp) {
      accessToken = getState().signup.currentUser?.profile.access_token;
    }

    dispatch(updateUserStart());
    axios
      .patch(URLS.updateUserProfile, data, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(updateUserSuccess({ ...currentUser, ...response.data }));
        dispatch(
          setPopupData({
            heading: 'User Information Update Successful',
            color: CONSTANTS.POPUP_COLORS.GREEN,
          })
        );
        dispatch(setShowPopup(true));
        if (shouldLogin) {
          const updatedCurrentUser = {
            email: response.data.email,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            middle_name: response.data.middle_name,
            name: response.data.name,
            profile: response.data.profile,
            user_type: response.data.user_type,
            uuid: response.data.uuid,
            access_token: response.data.profile.access_token,
            refresh_token: response.data.profile.refresh_token,
          };
          dispatch(userLoginSuccess(updatedCurrentUser));
          clientSignedUp();
        }
      })
      .catch((error) => {
        dispatch(updateUserFailure(error.message));
        const errorObject = error.response.data;
        const errorMessage = errorObject
          ? Object.values(errorObject)
          : 'User Info update failed, please try again later';
        dispatch(
          setPopupData({
            heading: 'User Information Update Unsuccessful',
            message: errorMessage,
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
      });
  };
};

export const updateUserPasswordAsync = (data) => {
  return (dispatch) => {
    dispatch(updateUserStart());
    API.patch(URLS.updateUserPassword, data)
      .then(() => {
        dispatch(updateUserPasswordSuccess());
        dispatch(
          setPopupData({
            heading: 'Password Update Successful',
            message: 'Your password was updated successfully',
            color: CONSTANTS.POPUP_COLORS.GREEN,
          })
        );
        dispatch(setShowPopup(true));
      })
      .catch((error) => {
        const errorObject = error.response.data;

        const errorMessage = errorObject
          ? Object.values(errorObject)
          : 'Password Update failed, please try again later';

        dispatch(updateUserFailure(errorMessage));
        dispatch(
          setPopupData({
            heading: 'Password Update Unsuccessful',
            message: errorMessage,
            color: CONSTANTS.POPUP_COLORS.RED,
          })
        );
        dispatch(setShowPopup(true));
      });
  };
};

export const healthCheckSuccess = () => ({
  type: UserActionTypes.HEALTH_CHECK_SUCCESS,
});

export const performHealthCheck = () => {
  return () => {
    axios.get(URLS.health_check).catch(() => {
      // Silently fail - no action needed if health check fails
    });
  };
};
