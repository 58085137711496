import moment from 'moment';

import {
  fetchAllChatsStartAsync,
  fetchSingleChatStartAsync,
  fetchSingleGroupChatStartAsync,
  fetchAllGroupChatsStartAsync,
} from '../redux/inbox/InboxAction';
import { fetchNotificationsStartAsync, setShowNotificationsFlag } from '../redux/dashboard/DashboardAction';
import { store } from '../redux/store';
import CONSTANTS from './constants/CONSTANTS';

export const isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0;
};

/**
 *  * This function takes in a list of user ids and converts it to an Object of selected CLients

 * @param {list} filteredIds  list of filters ids that need to be converted to an object
 * @param {object} originalObject the original objects list before it was filtered that we got from the backend
 * @returns {object} an Object of filtered ids that we sent in
 */
export const filteredIdsToObject = (filteredIds, originalObject) => {
  return filteredIds.reduce((object, key) => {
    return { ...object, [key]: originalObject[key] };
  }, {});
};

export const getDateTwelveYearsAgo = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 12);

  let month = date.getMonth() + 1;
  month = month.toString();
  if (month < 10) month = `0${month}`;

  let day = date.getDate();
  day = day.toString();
  if (day < 10) day = `0${day}`;

  return `${date.getFullYear()}-${month}-${day}`;
};

/** this function is used by firebase to fetch new messages only when a new message arrives */
export const fetchMessages = () => {
  const { showChatState, currentChat } = store.getState().inbox;
  store.dispatch(fetchAllChatsStartAsync());

  /** if a chat box is open then we need to fetch any new messages that we got there as well */
  if (showChatState) {
    store.dispatch(fetchSingleChatStartAsync(currentChat.uuid));
  }
};

export const isOnePlanEachMonth = (plans) => {
  const yearMonths = plans.map((plan) => {
    const [year, month] = plan.starting_date.split('-');
    return `${year}${month}`;
  });
  const tempSet = new Set(yearMonths);
  return tempSet.size === plans.length;
};

export const formatClientType = (clientType) => (clientType ? clientType.replace('_', ' ') : 'incomplete');

/** this function is used by firebase to fetch new group messages only when a new message arrives */
export const fetchGroupMessages = () => {
  const { showGroupChatState, currentGroupChat } = store.getState().inbox;
  store.dispatch(fetchAllGroupChatsStartAsync());

  /** if a chat box is open then we need to fetch any new messages that we got there as well */
  if (showGroupChatState) {
    store.dispatch(fetchSingleGroupChatStartAsync(currentGroupChat.uuid));
  }
};

/** this function is used by firebase to fetch new notifications only when a new notification arrives */
export const fetchNotifications = () => {
  store.dispatch(fetchNotificationsStartAsync());
  store.dispatch(setShowNotificationsFlag(true));
};

export const momentFormatter = (date, format) => {
  return moment(date).format(format);
};

export const startDateOfNextMonth = () => {
  /** this date is only needed if the plan type is later  for plan scheduling */
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  return momentFormatter(date, CONSTANTS.YEAR_MONTH_DAY);
};

export const getMonthlyCharge = (amount) => {
  /** we want our value to be accurate to 2 decimal places thus we multiply our initial amount
   * with 100 then divide by 12 to get the monthly amount and again divide by 100 to get our 2
   * decimal places, but even then sometimes if the last digit in the decimal places is 0 we
   * still want it to show 2 decimal places and we will add .toFixed(2) to ensure that, but incase
   * that both the last digits are 0 which means we have a whole number we will return that without
   * adding any decimal places
   */

  const monthly = Math.round((amount * 100) / 12);
  if (monthly % 100 === 0) return monthly / 100;
  return (monthly / 100).toFixed(2);
};

export const isPlanExpired = (validTill) => {
  const planValidTill = new Date(validTill * 1000);
  const todaysDate = new Date();

  return Date.parse(planValidTill) < Date.parse(todaysDate);
};

export const getFullName = (user) => {
  return `${user.first_name ? user.first_name : ''} ${user.middle_name ? user.middle_name : ''} ${
    user.last_name ? user.last_name : ''
  }`;
};

export const formatTime = (date, formatType) => {
  return date.toLocaleTimeString([], formatType);
};

export const formatDate = (date, formatType) => {
  return date.toLocaleDateString([], formatType);
};

export const convertTypeToClassName = (type) => {
  return type ? type.replaceAll('_', '-') : null;
};

export const formatSessionType = (type) => {
  return type ? type.replaceAll('_', ' ') : null;
};

export const capitalizeFirstLetter = (word) => {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  return null;
};

export const formatSubscriptionStatus = (subscriptionStatus) => {
  switch (subscriptionStatus) {
    case 'incomplete_expired':
      return 'Expired';
    case 'No Subscription':
      return 'No Sub';
    default:
      return capitalizeFirstLetter(subscriptionStatus);
  }
};

/** This function will return the keys of array of objects */

export const getKeys = (obj) => {
  if (obj) {
    return Object.values(obj).reduce((accumulator, value) => Object.keys(value), 0);
  }
  return null;
};

export const generateReportLabels = (keysList) => {
  if (keysList) {
    return keysList.map((value) => ({ label: value.toUpperCase().replaceAll('_', ' '), key: value }));
  }
  return null;
};

export const isArray = (a) => {
  return Array.isArray(a);
};
export const isObject = (o) => {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};
const toSnake = (s) => {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.split(' ').join('_').toLowerCase();
};

export const keysToCamel = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

export const keysToSnake = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      if (k.toLowerCase().includes('file')) {
        n[toSnake(k)] = o[k];
      } else {
        n[toSnake(k)] = keysToSnake(o[k]);
      }
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => {
      return keysToSnake(i);
    });
  }

  return o;
};
export const formatDateOfBirth = (dateOfBirth) => {
  if (dateOfBirth === null) return '';
  const [year, month, day] = dateOfBirth.split('-');
  return `${day}-${month}-${year}`;
};

// * help process error messages
export const handleErrorMessage = (error) => {
  let errorMessage = '';
  if (error.response.data.detail) {
    errorMessage = error.response.data.detail;
  } else if (error.response.data) {
    errorMessage = error.response.data;
  } else if (error.message) {
    errorMessage = error.message;
  }
  return errorMessage;
};

/**
 * * Gets the sponsorship id associated with a given sponsorship level.
 *
 * @param {string} sponsorshipLevel - The name of the sponsorship level.
 * @returns {number|null} - The sponsorship id or null if not found.
 */
export const getSponsorshipId = (sponsorshipLevel) => {
  // Define a mapping of sponsorship levels to their corresponding ids.
  const sponsorshipLevelMap = {
    Platinum: 1, // 1 represents the Platinum level
    Gold: 2, // 2 represents the Gold level
    Silver: 3, // 3 represents the Silver level
  };

  // Use the mapping to retrieve the id associated with the provided level.
  // If the level is not found in the map, it returns null.
  return sponsorshipLevelMap[sponsorshipLevel] || null;
};
