import { ReactComponent as MaleIcon } from '../../assets/images/svg/maleIcon.svg';
import { ReactComponent as FemaleIcon } from '../../assets/images/svg/femaleIcon.svg';
import { ReactComponent as Neutral } from '../../assets/images/svg/genderNeutralIcon.svg';
import { ReactComponent as GroupIcon } from '../../assets/images/svg/groupIcon.svg';
import { ReactComponent as ProgressImage } from '../../assets/images/svg/ProgressImage.svg';

import CONSTANTS from '../../utils/constants/CONSTANTS';

import './index.scss';

const ProfilePicture = ({ gender, profilePicture, group, progressImage }) => {
  /** if the gender exists we will see if it is female or not else we will set to false because if gender does not exist then keeping track of female or male does not matter */
  const isFemale = gender ? gender.toLowerCase() === CONSTANTS.FEMALE : false;

  /** doing this to avoid nested ternary */
  /** selecting between male and female icons */
  const Icon = isFemale ? FemaleIcon : MaleIcon;
  /** seeing if we have gender we will use the female or male icon else we will use Neutral icons */
  const Image = gender ? Icon : Neutral;
  /** This check will confirm whether the final image is a group or person and will assign image based on that  */
  let FinalImage = group ? GroupIcon : Image;
  FinalImage = progressImage ? ProgressImage : FinalImage;
  /** if we have profile picture then we will display that else we will show the icon */
  return profilePicture ? (
    <img src={profilePicture} alt="user Profile" className="image" />
  ) : (
    <FinalImage className="image" />
  );
};

export default ProfilePicture;
