const ClientActionTypes = {
  FETCH_ALL_CLIENTS_START: 'FETCH_ALL_CLIENTS_START',
  FETCH_ALL_CLIENTS_SUCCESS: 'FETCH_ALL_CLIENTS_SUCCESS',
  FETCH_ALL_CLIENTS_FAILURE: 'FETCH_ALL_CLIENTS_FAILURE',
  FETCH_ALL_GROUPS_START: 'FETCH_ALL_GROUPS_START',
  FETCH_ALL_GROUPS_SUCCESS: 'FETCH_ALL_GROUPS_SUCCESS',
  FETCH_ALL_GROUPS_FAILURE: 'FETCH_ALL_GROUPS_FAILURE',
  FETCH_SINGLE_CLIENT_START: 'FETCH_SINGLE_CLIENT_START',
  FETCH_SINGLE_CLIENT_SUCCESS: 'FETCH_SINGLE_CLIENT_SUCCESS',
  FETCH_SINGLE_CLIENT_FAILURE: 'FETCH_SINGLE_CLIENT_FAILURE',
  FETCH_SINGLE_GROUP_START: 'FETCH_SINGLE_GROUP_START',
  FETCH_SINGLE_GROUP_SUCCESS: 'FETCH_SINGLE_GROUP_SUCCESS',
  FETCH_SINGLE_GROUP_FAILURE: 'FETCH_SINGLE_GROUP_FAILURE',
  FETCH_PROGRESS_DATA_START: 'FETCH_PROGRESS_DATA_START',
  FETCH_PROGRESS_DATA_SUCCESS: 'FETCH_PROGRESS_DATA_SUCCESS',
  FETCH_PROGRESS_DATA_FAILURE: 'FETCH_PROGRESS_DATA_FAILURE',
  FETCH_TRAINER_INFO: 'FETCH_TRAINER_INFO',
  SET_SORTED_DATA: 'SET_SORTED_DATA',
  SET_CURRENT_CLIENT: 'SET_CURRENT_CLIENT',
  SET_SHOW_CLIENT_STATE: 'SET_SHOW_CLIENT_STATE',
  SET_SHOW_GROUP_STATE: 'SET_SHOW_GROUP_STATE',
  SET_ALL_CLIENTS: 'SET_ALL_CLIENTS',
  SET_ALL_GROUPS: 'SET_ALL_GROUPS',
  SET_EDIT_AVAILABLE_SESSIONS: 'SET_EDIT_AVAILABLE_SESSIONS',
  UPDATE_AVAILABLE_SESSIONS_START: 'UPDATE_AVAILABLE_SESSIONS_START',
  UPDATE_AVAILABLE_SESSIONS_SUCCESS: 'UPDATE_AVAILABLE_SESSIONS_SUCCESS',
  UPDATE_AVAILABLE_SESSIONS_FAILURE: 'UPDATE_AVAILABLE_SESSIONS_FAILURE',
  SET_POPUP: 'SET_POPUP',
  SET_POPUP_DATA: 'SET_POPUP_DATA',
  INITIALIZE_CLIENT_STATE: 'INITIALIZE_CLIENT_STATE',
  SET_CURRENT_GROUP: 'SET_CURRENT_GROUP',
  SHOW_ASSIGN_TRAINER: 'SHOW_ASSIGN_TRAINER',
  GENERATE_COUPON_START: 'GENERATE_COUPON_START',
  GENERATE_COUPON_SUCCESS: 'GENERATE_COUPON_SUCCESS',
  GENERATE_COUPON_FAILURE: 'GENERATE_COUPON_FAILURE',
  TOGGLE_COUPON_CODE_START: 'TOGGLE_COUPON_CODE_START',
  TOGGLE_COUPON_CODE_SUCCESS: 'TOGGLE_COUPON_CODE_SUCCESS',
  TOGGLE_COUPON_CODE_FAILURE: 'TOGGLE_COUPON_CODE_FAILURE',
  SET_CURRENT_COUPON: 'SET_CURRENT_COUPON',
  SET_NEW_CLIENT_ADDED: 'SET_NEW_CLIENT_ADDED',
  SET_TOTAL_CLIENT_PAGES: 'SET_TOTAL_CLIENT_PAGES',
};

export default ClientActionTypes;
