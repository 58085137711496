import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';

import Spinner from '../Spinner';

import { ReactComponent as Bell } from '../../assets/images/svg/bell.svg';

import useGetUnreadNotificationsCount from '../../customHooks/useGetUnreadNotificationsCount';
import getTime from '../../pages/Inbox/SingleChat/helperFunctions';

import {
  fetchNotificationsStartAsync,
  updateNotificationsReadStatus,
  setShowNotificationsFlag,
} from '../../redux/dashboard/DashboardAction';
import CONSTANTS from '../../utils/constants/CONSTANTS';

import './index.scss';

const Notification = () => {
  const dispatch = useDispatch();

  const allNotifications = useSelector((state) => state.dashboard.allNotifications);
  const isFetchingNotifications = useSelector((state) => state.dashboard.isFetchingNotifications);
  const showNotificationsCount = useSelector((state) => state.dashboard.showNotifications);

  const [isShowPopup, setIsShowPopup] = useState(false);

  const unreadNotificationsCount = useGetUnreadNotificationsCount();

  const showNotifications = () => {
    if (!isShowPopup) {
      dispatch(fetchNotificationsStartAsync());
      dispatch(setShowNotificationsFlag(false));
    } else {
      dispatch(updateNotificationsReadStatus());
    }
    setIsShowPopup(!isShowPopup);
  };

  const handleClose = () => {
    setIsShowPopup(false);
    dispatch(updateNotificationsReadStatus());
  };

  return (
    <>
      <div className="bell-icon">
        <Bell style={CONSTANTS.ICON_STYLES} onClick={showNotifications} />
        {unreadNotificationsCount !== 0 && showNotificationsCount && (
          <span className="chat-count-badge">{unreadNotificationsCount}</span>
        )}
      </div>
      {isShowPopup && (
        <div className="notifications">
          <div className="close-btn">
            <AiOutlineClose style={CONSTANTS.ICON_STYLES} onClick={handleClose} />
          </div>
          <h1 className="heading">Notifications</h1>
          {isFetchingNotifications && <Spinner />}
          {!isFetchingNotifications && allNotifications && (
            <div className="all-notifications">
              {allNotifications &&
                allNotifications.notifications.map(({ is_seen: isSeen, icon, text, created_at: createdAt }) => (
                  <>
                    <div className={`${isSeen ? '' : 'unread'} notification`}>
                      <img className="notification-icon" src={icon} alt="" />
                      <div className="text-date">
                        <div className="notification-text">{text}</div>
                        <div className="created-at">{getTime(createdAt)}</div>
                      </div>
                    </div>
                    <div className="line-break" />
                  </>
                ))}
            </div>
          )}
          {!allNotifications ? (
            <div className="text">
              <p>No notifications at the moment</p>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Notification;
