import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const NoMatch = ({ component: Component, ...routeProps }) => {
  const userType = useSelector((state) => state.user.userType);
  const redirectPath = userType ? '/dashboard' : '/';
  return <Navigate {...routeProps} to={redirectPath} />;
};

export default NoMatch;
