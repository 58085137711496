const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_USER_TYPE: 'SET_USER_TYPE',
  UPDATE_USER_START: ' UPDATE_USER_START',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  UPDATE_USER_PASSWORD_SUCCESS: 'UPDATE_USER_PASSWORD_SUCCESS',
  INITIALIZE_STATE: 'INITIALIZE_STATE',
  SET_ACCESS_AND_REFRESH_TOKEN: 'SET_ACCESS_AND_REFRESH_TOKEN',
  USER_LOGIN_START: 'USER_LOGIN_START',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  SET_REMEMBER_ME: 'SET_REMEMBER_ME',
  SET_LOGIN_STATE: 'SET_LOGIN_STATE',
  SET_ALREADY_SIGNED_UP: 'SET_ALREADY_SIGNED_UP',
  SET_SHOW_POPUP: 'SET_SHOW_POPUP',
  SET_POPUP_DATA: 'SET_POPUP_DATA',
  SET_USER_SETTINGS_TAB: 'SET_USER_SETTINGS_TAB',
  FETCH_USER_SESSION_DETAIL_START: 'FETCH_USER_SESSION_DETAIL_START',
  FETCH_USER_SESSION_DETAIL_SUCCESS: 'FETCH_USER_SESSION_DETAIL_SUCCESS',
  FETCH_USER_SESSION_DETAIL_FAILURE: 'FETCH_USER_SESSION_DETAIL_FAILURE',
  COMPLETE_PROFILE_MESSAGE_SUCCESS: 'COMPLETE_PROFILE_MESSAGE_SUCCESS',
  COMPLETE_PROFILE_MESSAGE_FAILURE: 'COMPLETE_PROFILE_MESSAGE_FAILURE',
  CANCELLED_SUBSCRIPTION_FOR_MANUAL_USER: 'CANCELLED_SUBSCRIPTION_FOR_MANUAL_USER',
  HEALTH_CHECK_SUCCESS: 'HEALTH_CHECK_SUCCESS',
};

export default UserActionTypes;
