/* eslint-disable import/no-cycle */
import axios from 'axios';

import * as API from '../api';

import PaymentActionTypes from './PaymentActionTypes';
import { setShowPopup, updateUserAsync, setPopupData, cancelledSubscriptionForManualUser } from '../user/UserAction';
import { setCurrentForm, setCurrentEmployeeForm } from '../signup/SignupAction';

import URLS from '../../utils/constants/URLS';
import CONSTANTS from '../../utils/constants/CONSTANTS';

export const setPaymentPlan = (plan) => ({
  type: PaymentActionTypes.SET_PAYMENT_PLAN,
  payload: plan,
});

export const setPaymentOptions = (options) => ({
  type: PaymentActionTypes.SET_PAYMENT_OPTIONS,
  payload: options,
});

export const sendStripeDataStart = () => ({
  type: PaymentActionTypes.SENDING_STRIPE_DATA_START,
});

export const sendStripeDataSuccess = () => ({
  type: PaymentActionTypes.SENDING_STRIPE_DATA_SUCCESS,
});

export const sendStripeDataFailure = (error) => ({
  type: PaymentActionTypes.SENDING_STRIPE_DATA_FAILURE,
  payload: error,
});

export const planFetchingStart = () => ({
  type: PaymentActionTypes.PLAN_FETCHING_START,
});

export const planFetchingSuccess = () => ({
  type: PaymentActionTypes.PLAN_FETCHING_SUCCESS,
});

export const planFetchingFailure = (error) => ({
  type: PaymentActionTypes.PLAN_FETCHING_FAILURE,
  payload: error,
});

export const subscribeNewPlanStart = () => ({
  type: PaymentActionTypes.SUBSCRIBE_NEW_PLAN_START,
});

export const subscribeNewPlanSuccess = () => ({
  type: PaymentActionTypes.SUBSCRIBE_NEW_PLAN_SUCCESS,
});

export const subscribeNewPlanFailure = (error) => ({
  type: PaymentActionTypes.SUBSCRIBE_NEW_PLAN_FAILURE,
  payload: error,
});

export const setUsersCard = (card) => ({
  type: PaymentActionTypes.SET_USERS_CARD,
  payload: card,
});

export const setUserSubscription = (subscription) => ({
  type: PaymentActionTypes.SET_USERS_SUBSCRIPTION,
  payload: subscription,
});

export const setBuySubscriptionState = (state) => ({
  type: PaymentActionTypes.SET_BUY_SUBSCRIPTION_STATE,
  payload: state,
});

export const updateCardDetailsStart = () => ({
  type: PaymentActionTypes.UPDATE_CARD_DETAILS_START,
});

export const updateCardDetailsSuccess = () => ({
  type: PaymentActionTypes.UPDATE_CARD_DETAILS_SUCCESS,
});

export const updateCardDetailsFailure = (message) => ({
  type: PaymentActionTypes.UPDATE_CARD_DETAILS_FAILURE,
  payload: message,
});

export const cancelSubscriptionStart = () => ({
  type: PaymentActionTypes.CANCEL_SUBSCRIPTION_START,
});

export const cancelSubscriptionSuccess = () => ({
  type: PaymentActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
});

export const cancelSubscriptionFailure = (message) => ({
  type: PaymentActionTypes.CANCEL_SUBSCRIPTION_FAILURE,
  payload: message,
});

export const setStripeDataFlag = (flag) => ({
  type: PaymentActionTypes.SET_STRIPE_DATA_FLAG,
  payload: flag,
});

export const cardRetrieveSuccessful = () => ({
  type: PaymentActionTypes.CARD_RETRIEVE_SUCCESSFUL,
});

export const cardRetrieveFail = () => ({
  type: PaymentActionTypes.CARD_RETRIEVE_FAIL,
});

export const initializePaymentReducer = () => ({ type: PaymentActionTypes.INITIALIZE_PAYMENT_REDUCER });

export const getPaymentOptions = (isSignedIn) => {
  return (dispatch, getState) => {
    let accessToken = getState().signup.currentUser?.profile.access_token;
    if (isSignedIn) {
      accessToken = getState().user.currentUser.access_token;
    }
    dispatch(planFetchingStart());
    axios
      .get(URLS.paymentOptions, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(setPaymentOptions(response.data));
        dispatch(planFetchingSuccess());
      })
      .catch(() => {
        dispatch(planFetchingFailure('Subscription Plans Not Available'));
      });
  };
};

export const sendStripeData = (data, confirmStripePayment, setIsShowLocalPopup, setErrorMessage) => {
  return (dispatch, getState) => {
    const accessToken = getState().signup.currentUser?.profile.access_token;

    dispatch(sendStripeDataStart());
    axios
      .post(URLS.saveStripeDetails, data, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dispatch(sendStripeDataSuccess());
        if (response.data.client_secret === null) {
          dispatch(setShowPopup(true));
          dispatch(updateUserAsync(response.data.user_data, true));
          const SubscriptionType = response.data.user_data?.profile?.program_type;
          dispatch(
            setPopupData({
              heading: 'Successful',
              message: 'Your subscription has been processed successfully. Hope you enjoy Omni Active!',
              note:
                SubscriptionType === CONSTANTS.ONLINE
                  ? 'Your one week of free trial has started'
                  : 'Your subscription has started',
              color: CONSTANTS.POPUP_COLORS.GREEN,
            })
          );
          dispatch(setShowPopup(true));
          dispatch(setCurrentForm(CONSTANTS.GENDER_INFO));
          dispatch(setCurrentEmployeeForm(CONSTANTS.GENDER_INFO));
        } else confirmStripePayment(response.data);
      })
      .catch((error) => {
        let errorMessage = '';
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        } else {
          [errorMessage] = error.response.data;
        }
        setIsShowLocalPopup(true);
        setErrorMessage(errorMessage);
        dispatch(sendStripeDataFailure(error.response));
      });
  };
};

export const fetchPaymentDetailsStartAsync = (doStripe3dSecure) => {
  return (dispatch, getState) => {
    const { userDetails } = getState().signup;

    dispatch(subscribeNewPlanStart());

    API.get(URLS.retrieveInvoiceDetails)
      .then((response) => {
        dispatch(subscribeNewPlanSuccess());
        if (response.data.client_secret) doStripe3dSecure(response.data);
        else {
          dispatch(setBuySubscriptionState(CONSTANTS.PAYMENT_SUCCESSFUL));
          dispatch(updateUserAsync(userDetails));
        }
      })
      .catch((error) => {
        dispatch(subscribeNewPlanFailure(error.response.message));
      });
  };
};

export const newPlanSubscription = (data, showPopup, setPopupMsg, doStripe3dSecure) => {
  return (dispatch) => {
    dispatch(subscribeNewPlanStart());

    API.post(URLS.subscribeNewPlan, data)
      .then(() => {
        dispatch(subscribeNewPlanSuccess());
        dispatch(fetchPaymentDetailsStartAsync(doStripe3dSecure));
      })
      .catch((error) => {
        showPopup(true);
        setPopupMsg({
          heading: 'Subscription error',
          message: 'User is already subscribed',
          color: 'red',
        });
        dispatch(subscribeNewPlanFailure(error.message));
      });
  };
};

export const upgradeSubscription = (data, showPopup, setPopupMsg, doStripe3dSecure) => {
  return (dispatch) => {
    dispatch(subscribeNewPlanStart());
    API.post(URLS.upgradeSubscription, data)
      .then(() => {
        dispatch(subscribeNewPlanSuccess());
        dispatch(fetchPaymentDetailsStartAsync(doStripe3dSecure));
      })
      .catch((error) => {
        showPopup(true);
        setPopupMsg({
          heading: 'Subscription error',
          message: 'Something went wrong',
          color: 'red',
        });
        dispatch(subscribeNewPlanFailure(error.message));
      });
  };
};

export const getCardDetailsForManualUser = () => {
  return (dispatch) => {
    API.get(URLS.getCardDetails).then((response) => {
      dispatch(setUsersCard(response.data));
    });
  };
};

export const getCardDetails = () => {
  return (dispatch) => {
    API.get(URLS.getCardDetails)
      .then((response) => {
        dispatch(setUsersCard(response.data));
        dispatch(cardRetrieveSuccessful());
      })
      .catch(() => {
        dispatch(cardRetrieveFail());
      });
  };
};

export const updateCardDetails = (data, showPopup, setPopupMessage) => {
  return (dispatch) => {
    dispatch(updateCardDetailsStart());
    API.patch(URLS.updateCardDetails, data)
      .then(() => {
        showPopup(true);
        setPopupMessage({
          heading: 'Card Details Updated successfully',
          color: 'green',
        });
        dispatch(getCardDetails());
        dispatch(updateCardDetailsSuccess());
      })
      .catch((error) => {
        showPopup(true);
        setPopupMessage({
          heading: 'Card Details Updation failed',
          message: 'please try again later or contact your trainer',
          color: 'red',
        });
        dispatch(updateCardDetailsFailure(error.message));
      });
  };
};

export const getSubscriptionDetails = () => {
  return (dispatch) => {
    API.get(URLS.getUserSubscription).then((response) => {
      dispatch(setUserSubscription(response.data));
    });
  };
};

export const cancelCardSubscription = (showPopup, setPopupMessage) => {
  return (dispatch) => {
    dispatch(cancelSubscriptionStart());
    API.patch(URLS.cancelPayment)
      .then(() => {
        dispatch(cancelSubscriptionSuccess());
        dispatch(cancelledSubscriptionForManualUser());
        showPopup(true);
        setPopupMessage({
          heading: 'Subscription cancelled',
          note: 'please note that you can use our app till your payment period expires',
          color: 'green',
        });

        dispatch(getSubscriptionDetails());
      })
      .catch((error) => {
        dispatch(cancelSubscriptionFailure(error.message));
        showPopup(true);
        setPopupMessage({
          heading: 'Cancel Subscription failed',
          message: 'please try again later or contact your trainer',
          color: 'red',
        });
      });
  };
};
