import { useLocation, useMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';

import CONSTANTS from '../../utils/constants/CONSTANTS';

import './index.scss';

const TopBar = () => {
  const { pathname } = useLocation();

  /** if the user is on the Dashboard the top bar needs to be a little extended for styling and also
   * the background image needs to be changed, thus this class is needed */
  const extended = pathname === CONSTANTS.URL_LIST.DASHBOARD ? 'extend' : '';

  const hide = useMatch('/user/*') ? 'hide' : '';

  const [pageDetails, setPageDetails] = useState({
    heading: 'Dashboard',
    subHeading: 'My Dashboard',
  });

  useEffect(() => {
    setPageDetails(CONSTANTS.URL_VALUES[pathname]);
  }, [pathname]);

  return (
    <div className={`top-bar ${extended} ${hide}`}>
      <div className="top-left">
        {pageDetails && (
          <>
            <div className="heading">{pageDetails.heading}</div>
            <div className="sub-heading"> {pageDetails.subHeading}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default TopBar;
