import EmployerCodeActionTypes from './EmployerCodeActionTypes';

const INITIAL_STATE = {
  employerCode: null,
};

const employerCodeReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case EmployerCodeActionTypes.SET_EMPLOYER_CODE:
      return {
        ...state,
        employerCode: action.payload,
      };
    case EmployerCodeActionTypes.INITIALIZE_EMPLOYERCODE_STATE:
      return {
        employerCode: null,
      };
    default:
      return state;
  }
};

export default employerCodeReducer;
