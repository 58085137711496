import CONSTANTS from '../../utils/constants/CONSTANTS';
import FundraiserActionTypes from './FundraiserActionTypes';

const INITIAL_STATE = {
  currentFundraiserForm: CONSTANTS.FUNDRAISER_CODE,
  fundraiserCode: null,
  isVerifyingSchoolCode: false,
  schoolCodeVerified: false,
  schoolCodeVerificationError: null,
  schoolName: null,
  schoolLogo: null,
  selectedSponsorship: null,
  sponsorshipTotalAmount: null,
  sponsorshipDuration: null,
  donorEmail: null,
  schoolList: null,
  fetchingSchoolList: false,
  selectedSchoolCode: null,
  selectedSchool: null,
};

// eslint-disable-next-line default-param-last
const fundraiserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FundraiserActionTypes.INITIALIZE_FUNDRAISER_STATE:
      return {
        currentFundraiserForm: CONSTANTS.FUNDRAISER_CODE,
        fundraiserCode: null,
        isVerifyingSchoolCode: false,
        schoolCodeVerified: false,
        schoolCodeVerificationError: null,
        schoolName: null,
        schoolLogo: null,
        selectedSponsorship: null,
        sponsorshipTotalAmount: null,
        sponsorshipDuration: null,
        donorEmail: null,
        schoolList: null,
        fetchingSchoolList: false,
        selectedSchoolCode: null,
        selectedSchool: null,
      };
    case FundraiserActionTypes.SET_CURRENT_FUNDRAISER_FORM:
      return {
        ...state,
        currentFundraiserForm: action.payload,
      };
    case FundraiserActionTypes.SET_FUNDRAISER_CODE:
      return {
        ...state,
        fundraiserCode: action.payload,
      };
    case FundraiserActionTypes.SET_SCHOOL_NAME:
      return {
        ...state,
        schoolName: action.payload,
      };
    case FundraiserActionTypes.SET_SCHOOL_LOGO:
      return {
        ...state,
        schoolLogo: action.payload,
      };
    case FundraiserActionTypes.SET_DONOR_EMAIL:
      return {
        ...state,
        donorEmail: action.payload,
      };
    case FundraiserActionTypes.VERIFY_SCHOOL_CODE_START:
      return {
        ...state,
        isVerifyingSchoolCode: true,
        schoolCodeVerified: false,
        schoolCodeVerificationError: null,
      };
    case FundraiserActionTypes.VERIFY_SCHOOL_CODE_SUCCESS:
      return {
        ...state,
        isVerifyingSchoolCode: false,
        schoolCodeVerified: true,
      };
    case FundraiserActionTypes.VERIFY_SCHOOL_CODE_FAILURE:
      return {
        ...state,
        isVerifyingSchoolCode: false,
        schoolCodeVerified: false,
        schoolCodeVerificationError: action.payload,
      };

    case FundraiserActionTypes.SET_SELECTED_SPONSORSHIP:
      return {
        ...state,
        selectedSponsorship: action.payload,
      };
    case FundraiserActionTypes.SET_SPONSORSHIP_TOTAL_AMOUNT:
      return {
        ...state,
        sponsorshipTotalAmount: action.payload,
      };
    case FundraiserActionTypes.SET_SPONSORSHIP_DURATION:
      return {
        ...state,
        sponsorshipDuration: action.payload,
      };
    case FundraiserActionTypes.SET_SCHOOL_FETCHING_FLAG:
      return {
        ...state,
        fetchingSchoolList: action.payload,
      };
    case FundraiserActionTypes.SET_SCHOOL_LIST:
      return {
        ...state,
        fetchingSchoolList: false,
        schoolList: action.payload,
      };
    case FundraiserActionTypes.SET_SELECTED_SCHOOL_CODE:
      return {
        ...state,
        selectedSchoolCode: action.payload,
      };
    case FundraiserActionTypes.SET_SELECTED_SCHOOL:
      return {
        ...state,
        selectedSchool: action.payload,
      };
    default:
      return state;
  }
};

export default fundraiserReducer;
