import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import CONSTANTS from '../../utils/constants/CONSTANTS';
import SideNavOption from './SideNavOption';

import { logOut } from '../../redux/user/UserAction';

import './index.scss';

const SideNav = () => {
  const [showDetailView, setShowDetailView] = useState(false);
  const closeNav = () => {
    setShowDetailView(false);
  };
  const openNav = () => {
    setShowDetailView(true);
  };
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const currentPage = pathname.split('/').slice(-1)[0];

  return (
    <>
      <div className="ham-icon">
        <FiMenu style={CONSTANTS.EXTRA_BIG_ICON} onClick={openNav} />
      </div>
      {showDetailView && (
        <div id="mySidenav" className="sidenav">
          <div className="closebtn">
            <AiOutlineClose style={CONSTANTS.BIG_ICON} onClick={closeNav} />
          </div>

          <SideNavOption
            heading="Home"
            selected={currentPage === 'dashboard' ? 1 : 0}
            location={CONSTANTS.CLIENT_ROUTES.DASHBOARD}
          />

          <SideNavOption
            heading="Workouts"
            selected={currentPage === 'workouts' ? 1 : 0}
            location={CONSTANTS.CLIENT_ROUTES.WORKOUTS}
          />
          <SideNavOption heading="Profile" selected={currentPage === 'profile' ? 1 : 0} location="/user/profile" />
          <a className="side-nav-options" target="_blank" href={CONSTANTS.shopUrl} rel="noreferrer noopener">
            <div className="side-nav-heading">Gear Shop</div>
          </a>
          <SideNavOption
            heading="Meal Services"
            selected={currentPage === 'mealServices' ? 1 : 0}
            location="/user/mealServices"
          />
          <SideNavOption
            handleClick={() => dispatch(logOut())}
            heading="Logout"
            selected={currentPage === 'createExercise' ? 1 : 0}
            location="/"
          />
        </div>
      )}
    </>
  );
};

export default SideNav;
