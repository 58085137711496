/** client Bar functions */

import { calculateAge } from '../SendPlanToInduvidual/helperFunctions';
import CONSTANTS from '../../../../utils/constants/CONSTANTS';

export const mapProgressToBarColor = (progress) => {
  if (Number(progress) < 33) return CONSTANTS.GREEN;
  if (Number(progress) < 66) return CONSTANTS.YELLOW;
  return CONSTANTS.RED;
};

export const calculatePlanProgress = (plan) => {
  const [year, month, day] = plan.starting_date.split('-');
  const startDate = new Date(`${month}/${day}/${year}`);
  const currentDate = new Date();
  const timeElasped = (currentDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
  /** converting time in days to percentage for the progress bar */
  const percetageProgress = (timeElasped * 100) / 30;
  return Math.min(percetageProgress, 100);
};

/** helper function for index js */

/**
 * This function recieves an object called filters that has main categories as keys and
 * list of sub categories as values. and also recives all clients. all the clients are then filtered
 * based on the active filters
 *
 * @param {object} filters an object containing mainCategories of filters as keys and the sub categories of each filter selected by user as a list
 * @param {list} allClients list of all clients
 * @param {list} listofFilters list of all the subCategories of the filters selected combined
 * @returns {list} list of filtered clients
 */

const filterByAge = (filters, mainCategory, filteredClientIds, allClients) => {
  let copyClientIds = [];
  return filters[mainCategory].reduce((list, subCategory) => {
    const [start, end] = subCategory.replaceAll('to', '').split('  ');
    copyClientIds = filteredClientIds.filter(
      (id) =>
        /** check if client has date of birth or not. if no then simply this client will be filtered out.
         * if yes then check if the age lies in the range selected by user in the filter and if yes then return that client */
        allClients[id].date_of_birth &&
        calculateAge(allClients[id].date_of_birth) >= start &&
        calculateAge(allClients[id].date_of_birth) <= end
    );
    return [...list, ...copyClientIds];
  }, []);
};

/** if the main category is related to plan status then we need to filter clients based on if they
 * dont have a plan or if they have a plan but it is about to expire
 * since the two types of filters for the plan status are mutually exclusive, we need to merge these
 * two results
 */
const filterByPlanStatus = (filters, mainCategory, filteredClientIds, allClients) => {
  let noPlan = [];
  let aboutToExpire = [];
  if (filters[mainCategory].includes(CONSTANTS.NO_PLAN)) {
    noPlan = filteredClientIds.filter((id) => allClients[id].plan === null);
  }
  if (filters[mainCategory].includes(CONSTANTS.ALMOST_COMPLETE)) {
    aboutToExpire = filteredClientIds.filter(
      (id) => allClients[id].plan !== null && calculatePlanProgress(allClients[id].plan) > 66
    );
  }
  return [...aboutToExpire, ...noPlan];
};

const makeParameterValidString = (input) => {
  return input
    .toLowerCase()
    .replace(/\sto\s/g, '-')
    .replace(/\s/g, '_');
};

export const constructURLwithPageAndFilters = (urlString, filters, page) => {
  const url = new URL(urlString);
  // Add pageNumber if it exits
  if (page) url.searchParams.append('page', page);
  if (filters) {
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (Array.isArray(value)) {
        // Add multiple values for the same key
        value.forEach((v) => url.searchParams.append(makeParameterValidString(key), makeParameterValidString(v)));
      } else if (value) {
        // Add single value
        url.searchParams.append(makeParameterValidString(key), makeParameterValidString(value));
      }
    });
  }
  return url.toString();
};

export const filterData = (filters, allClients, listofFilters) => {
  let filteredClientIds = Object.keys(allClients);

  /** if there are any filter selected by user then apply filters */
  if (listofFilters.length) {
    /** mapping over all filter categories */
    Object.keys(filters).forEach((mainCategory) => {
      /** if the value corresponding to the main category has any length then we apply filter */
      if (filters[mainCategory].length) {
        /** if the main category of the filter is age then filter by age else if main category is not
         * Plan status then filter based on that category (ie client type or client goal) */
        if (mainCategory === CONSTANTS.AGE) {
          filteredClientIds = filterByAge(filters, mainCategory, filteredClientIds, allClients);
        } else if (mainCategory !== CONSTANTS.PLAN_STATUS) {
          filteredClientIds = filteredClientIds.filter((id) =>
            filters[mainCategory].includes(allClients[id][CONSTANTS.MAP_CATEGORY_TO_FIELD[mainCategory]])
          );
        } else {
          filteredClientIds = filterByPlanStatus(filters, mainCategory, filteredClientIds, allClients);
        }
      }
    });
  }

  /** getting the list of clients from the list of ids */
  return filteredClientIds.reduce((array, clientKey) => {
    return [...array, allClients[clientKey]];
  }, []);
};
