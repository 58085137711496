const pathsAllowed = {
  client: [
    '/user',
    '/user/dashboard',
    '/user/workouts',
    '/user/profile',
    '/user/buySubscriptions',
    '/user/mealServices',
  ],
  trainer: [
    '/dashboard',
    '/dashboard/settings',
    '/workoutBuilder',
    '/workoutBuilder/createExercise',
    '/workoutBuilder/createWorkout',
    '/workoutBuilder/createPlan',
    '/workoutBuilder/sendPlan',
    '/clients',
    '/inbox',
    '/calendar',
  ],
  admin: [
    '/dashboard',
    '/dashboard/settings',
    '/dashboard/leaderboard',
    '/dashboard/couponCodes',
    '/workoutBuilder',
    '/workoutBuilder/createExercise',
    '/workoutBuilder/createWorkout',
    '/workoutBuilder/createPlan',
    '/workoutBuilder/sendPlan',
    '/trainers',
    '/clients',
    '/inbox',
    '/reporting',
    '/schoolList',
  ],
};

export default pathsAllowed;
