import { useDispatch, useSelector } from 'react-redux';
import { Link, useMatch } from 'react-router-dom';

import { initializeFundraiserState } from '../../redux/fundraiser/FundraiserActions';
import CONSTANTS from '../../utils/constants/CONSTANTS';

import './index.scss';

const AdminHeaderLinks = ({ unreadChatCount }) => {
  const dispatch = useDispatch();
  const isNewClientAdded = useSelector((state) => state.clients?.isNewClientAdded);

  const resetSchoolData = () => {
    dispatch(initializeFundraiserState());
  };

  return (
    <>
      <Link
        className={`${useMatch(CONSTANTS.URL_LIST.DASHBOARD) ? 'selected' : ''} option`}
        to={CONSTANTS.URL_LIST.DASHBOARD}
      >
        Dashboard
      </Link>
      <Link
        className={`${useMatch(CONSTANTS.URL_LIST.WORKOUT_BUILDER) ? 'selected' : ''} option`}
        to={CONSTANTS.URL_LIST.WORKOUT_BUILDER}
      >
        Workout Builder
      </Link>
      <Link
        className={`${useMatch(CONSTANTS.URL_LIST.TRAINERS) ? 'selected' : ''} option`}
        to={CONSTANTS.URL_LIST.TRAINERS}
      >
        Trainers
      </Link>
      <Link
        className={`${useMatch(CONSTANTS.URL_LIST.CLIENTS) ? 'selected' : ''} option`}
        to={CONSTANTS.URL_LIST.CLIENTS}
      >
        Clients{!isNewClientAdded || <span className="new-client-added" />}
      </Link>
      <Link className={`${useMatch(CONSTANTS.URL_LIST.INBOX) ? 'selected' : ''} option`} to={CONSTANTS.URL_LIST.INBOX}>
        Inbox{!unreadChatCount || <span className="chat-count-badge">{unreadChatCount}</span>}
      </Link>
      <Link
        className={`${useMatch(CONSTANTS.URL_LIST.REPORTING) ? 'selected' : ''} option`}
        to={CONSTANTS.URL_LIST.REPORTING}
      >
        Reporting
      </Link>
      <Link
        className={`${useMatch(CONSTANTS.URL_LIST.SCHOOL_LIST) ? 'selected' : ''} option`}
        to={CONSTANTS.URL_LIST.SCHOOL_LIST}
        onClick={resetSchoolData}
      >
        School List
      </Link>
    </>
  );
};
export default AdminHeaderLinks;
