import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TopBar from '../components/TopBar';
import Header from '../components/Header';

import CONSTANTS from '../utils/constants/CONSTANTS';
import pathsAllowed from './pathsAllowed';

const PermissionRoute = ({ component: Component, ...routeProps }) => {
  const { userType } = useSelector((state) => state.user);
  const location = useLocation();
  if (userType) {
    const isPathAllowed = pathsAllowed[userType].includes(location.pathname);
    const redirectPath = userType === CONSTANTS.CLIENT ? '/user/dashboard' : '/dashboard';
    return (
      <>
        <Header />
        <TopBar />
        {isPathAllowed ? <Component {...routeProps} /> : <Navigate to={redirectPath} />}
      </>
    );
  }
  return <Navigate to="/*" />;
};
export default PermissionRoute;
