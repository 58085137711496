import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PermissionRoute from './routes/PermissionRoute';
import PublicRoute from './routes/PublicRoute';
import Spinner from './components/Spinner';
import NoMatch from './routes/NoMatch';

import { getCurrentUser } from './firebase';
import { setTrainerDocumentPopup, setTrainerDocumentTab } from './redux/trainer/TrainerAction';

import CONSTANTS from './utils/constants/CONSTANTS';

import './App.css';

const WorkoutBuilder = lazy(() => import('./pages/WorkoutBuilder'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Trainers = lazy(() => import('./pages/Trainers'));
const Calendar = lazy(() => import('./pages/Calendar'));
const Reporting = lazy(() => import('./pages/Reporting'));
const SchoolsAdminPage = lazy(() => import('./pages/SchoolList'));
const Clients = lazy(() => import('./pages/Clients'));
const SignUp = lazy(() => import('./pages/SignUp'));
const CorporateWellness = lazy(() => import('./pages/CorporateWellness'));
const Fundraiser = lazy(() => import('./pages/Fundraiser'));
const Inbox = lazy(() => import('./pages/Inbox'));
const Login = lazy(() => import('./pages/Login'));
const User = lazy(() => import('./pages/Users'));
const CompleteProfile = lazy(() => import('./pages/CompleteProfile'));

function App() {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user.currentUser?.uuid);
  const userType = useSelector((state) => state.user.userType);
  const ndaDocument = useSelector((state) => state.user.currentUser?.profile?.non_compete_doc);
  const trainerContract = useSelector((state) => state.user.currentUser?.profile?.contractor_offer_sheet_doc);

  const logOutIfNotRememberMe = (e) => {
    e.preventDefault();
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));

    /** if user has chosen to not be remembered we will log them out */
    if (!rememberMe) localStorage.clear();
  };

  useEffect(() => {
    if ((userType === CONSTANTS.TRAINER && !ndaDocument) || (userType === CONSTANTS.TRAINER && !trainerContract)) {
      dispatch(setTrainerDocumentPopup(true));
      if (!ndaDocument) {
        dispatch(setTrainerDocumentTab(CONSTANTS.NDA_DOCUMENT));
      } else if (ndaDocument && !trainerContract) {
        dispatch(setTrainerDocumentTab(CONSTANTS.TRAINER_CONTRACT));
      }
    } else {
      dispatch(setTrainerDocumentPopup(false));
    }
  }, [ndaDocument, trainerContract]);

  useEffect(() => {
    if (userId) getCurrentUser(userId);
  }, [userId]);

  useEffect(() => {
    // window.addEventListener('unload', logOutIfNotRememberMe);
    return () => {
      window.removeEventListener('unload', logOutIfNotRememberMe);
    };
  }, []);

  return (
    <div className="App">
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route exact path="/" element={<PublicRoute component={Login} />} />
          <Route path="/signup" element={<PublicRoute component={SignUp} />} />
          <Route path="/wellness" element={<PublicRoute component={CorporateWellness} />} />
          <Route path="/fundraiser" element={<PublicRoute component={Fundraiser} />} />
          <Route path="/forgotPassword" element={<PublicRoute component={ForgotPassword} />} />
          <Route path="/change-password/:uidb64/:token" element={<PublicRoute component={ChangePassword} />} />
          <Route path="/complete-profile" element={<PublicRoute component={CompleteProfile} />} />
          <Route path="/user/*" element={<PermissionRoute component={User} />} />
          <Route path="/inbox" element={<PermissionRoute component={Inbox} />} />
          <Route path="/clients" element={<PermissionRoute component={Clients} />} />
          <Route path="/trainers" element={<PermissionRoute component={Trainers} />} />
          <Route path="/dashboard/*" element={<PermissionRoute component={Dashboard} />} />
          <Route path="/workoutBuilder/*" element={<PermissionRoute component={WorkoutBuilder} />} />
          <Route path="/calendar" element={<PermissionRoute component={Calendar} />} />
          <Route path="/reporting" element={<PermissionRoute component={Reporting} />} />
          <Route path="/schoolList" element={<PermissionRoute component={SchoolsAdminPage} />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
