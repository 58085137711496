import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import CONSTANTS from '../utils/constants/CONSTANTS';

const PublicRoute = ({ component: Component, ...routeProps }) => {
  const { userType } = useSelector((state) => state.user);
  if (userType) {
    const redirectPath = userType === CONSTANTS.CLIENT ? '/user/dashboard' : '/dashboard';
    return <Navigate to={redirectPath} />;
  }
  return <Component {...routeProps} />;
};

export default PublicRoute;
