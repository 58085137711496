const baseUrl = process.env.REACT_APP_BASE_URL;

const URLS = {
  login: `${baseUrl}/api/v1/user/login/`,

  exercise: `${baseUrl}/api/v1/workout/workout-builder/exercise/`,

  workout: `${baseUrl}/api/v1/workout/workout-builder/workout/`,

  plan: `${baseUrl}/api/v1/workout/workout-builder/plan/`,

  clients: `${baseUrl}/api/v1/workout/workout-builder/clients/`,

  singleClient: `${baseUrl}/api/v1/workout/workout-builder/client-detail/`,

  sendPlan: `${baseUrl}/api/v1/workout/workout-builder/send-plan/`,

  clientManagement: `${baseUrl}/api/v1/user/management/clients/`,

  assignTrainer: `${baseUrl}/api/v1/user/management/assign-trainer/`,

  assignTrainerToGroup: `${baseUrl}/api/v1/user/management/assign-trainer-to-coupon/`,

  trainers: `${baseUrl}/api/v1/user/management/trainers/`,

  removeTrainer: `${baseUrl}/api/v1/user/management/remove-trainer/`,

  addTrainer: `${baseUrl}/api/v1/user/management/add-trainer/`,

  deactivateTrainer: `${baseUrl}/api/v1/user/management/deactivate-trainer/`,

  reactivateTrainer: `${baseUrl}/api/v1/user/management/reactivate-trainer/`,

  updateRatePerSession: `${baseUrl}/api/v1/user/management/update-trainer-rate/`,

  updateUserProfile: `${baseUrl}/api/v1/user/profile/`,

  updateUserPassword: `${baseUrl}/api/v1/user/change-password/`,

  refreshAccessToken: `${baseUrl}/api/v1/user/token/refresh/`,

  allChats: `${baseUrl}/api/v1/chat/get-queries/`,

  singleChat: `${baseUrl}/api/v1/chat/get-messages/`,

  sendMessage: `${baseUrl}/api/v1/chat/send-message/`,

  allGroupChats: `${baseUrl}/api/v1/chat/get-group-chats/`,

  dashboard: `${baseUrl}/api/v1/user/management/dashboard/`,

  trainerPayments: `${baseUrl}/api/v1/user/management/dashboard/trainer-payments/`,

  userGrowth: `${baseUrl}/api/v1/user/management/dashboard/users-growth-graph/`,

  clientRankings: `${baseUrl}/api/v1/user/management/leaderboard/`,

  milestoneUsers: `${baseUrl}/api/v1/user/management/leaderboard/milestone-users/`,

  registerUser: `${baseUrl}/api/v1/user/register/`,

  onboardingSurvey: `${baseUrl}/api/v1/user/onboarding-survey/`,

  paymentOptions: `${baseUrl}/api/v1/payment/get-prices`,

  saveStripeDetails: `${baseUrl}/api/v1/payment/save-stripe-info/`,

  getCardDetails: `${baseUrl}/api/v1/payment/get-payment-method`,

  updateCardDetails: `${baseUrl}/api/v1/payment/update-payment-method/`,

  cancelPayment: `${baseUrl}/api/v1/payment/cancel-subscription/`,

  getUserSubscription: `${baseUrl}/api/v1/payment/get-subscription/`,

  subscribeNewPlan: `${baseUrl}/api/v1/payment/resubscribe/`,

  upgradeSubscription: `${baseUrl}/api/v1/payment/up-down-subscription/`,

  fetchAllGroups: `${baseUrl}/api/v1/user/management/dashboard/get-coupons/`,

  fetchSingleGroup: `${baseUrl}/api/v1/user/management/dashboard/get-coupon-users/`,

  session: `${baseUrl}/api/v1/workout/session/`,

  getToken: `${baseUrl}/api/v1/workout/get-agora-token/`,

  getPrograms: `${baseUrl}/api/v1/payment/get-programs/`,

  getProgramTypes: `${baseUrl}/api/v1/user/get-program-details/`,

  validateSchoolCode: `${baseUrl}/api/v1/school/validate-school/`,

  createFundraiser: `${baseUrl}/api/v1/school/fundraisers/`,

  fundraiserPayment: `${baseUrl}/api/v1/payment/fundraiser-payments/`,

  handleSchools: `${baseUrl}/api/v1/school/schools/`,

  donateFundraiser: `${baseUrl}/api/v1/school/fundraisers/update-donations-status/`,

  validateCoupon: `${baseUrl}/api/v1/user/validate-coupon/`,

  applyCoupon: `${baseUrl}/api/v1/user/apply-coupon/`,

  groupChat: `${baseUrl}/api/v1/chat/get-group-chat/`,

  sendGroupMessage: `${baseUrl}/api/v1/chat/send-group-message/`,

  groupChatMembers: `${baseUrl}/api/v1/chat/get-group-chat-users/`,

  getNotifications: `${baseUrl}/api/v1/user/get-notifications/`,

  setNotificationsSeen: `${baseUrl}/api/v1/user/set-notifications-seen/`,

  getTrainerReportSummary: `${baseUrl}/api/v1/report/trainer-report-summary/`,

  getSalesReportSummary: `${baseUrl}/api/v1/report/sales-report-summary/`,

  getClientReportSummary: `${baseUrl}/api/v1/report/client-report-summary/`,

  getTrainerDetailedReport: `${baseUrl}/api/v1/report/trainer-report/?`,

  getClientDetailedReport: `${baseUrl}/api/v1/report/client-report/?`,

  getSalesDetailedReport: `${baseUrl}/api/v1/report/sales-report/?`,

  getSalesGraph: `${baseUrl}/api/v1/report/sales-graph/?`,

  forgotPassword: `${baseUrl}/api/v1/user/password-reset/`,

  changePassword: `${baseUrl}/api/v1/user/password-reset-confirm/`,

  setTrainersAsRecruiter: `${baseUrl}/api/v1/user/make-recruiters/`,

  getTrainerTeam: `${baseUrl}/api/v1/user/recruiter-team-data/`,

  trainerPaymentGrowth: `${baseUrl}/api/v1/user/management/dashboard/trainer-payment-graph/`,

  getTrainerInfo: `${baseUrl}/api/v1/user/get-trainer-info/`,

  generateCoupon: `${baseUrl}/api/v1/user/management/generate-coupon/`,

  verifyEmail: `${baseUrl}/api/v1/user/verify-email/`,

  resendVerificationEmail: `${baseUrl}/api/v1/user/resend-verification-email/`,

  retrieveInvoiceDetails: `${baseUrl}/api/v1/payment/retrieve-invoice-details/`,

  toggleCouponCode: `${baseUrl}/api/v1/user/management/dashboard/toggle-activate-coupon/`,

  markSessionAsComplete: `${baseUrl}/api/v1/workout/mark-session-complete/`,

  markSessionAsIncomplete: `${baseUrl}/api/v1/workout/mark-session-in-complete/`,

  addReferralCode: `${baseUrl}/api/v1/user/validate-referral-code/`,

  updateRemainingSessions: `${baseUrl}/api/v1/user/add-sessions/`,

  deleteLaterPlan: `${baseUrl}/api/v1/workout/user-plan/`,

  userSessionsDetail: `${baseUrl}/api/v1/user/get-client-session-count-details/`,

  customerAgreementData: `${baseUrl}/api/v1/user/customer-agreement-data/`,

  customerAgreementDoc: `${baseUrl}/api/v1/user/agreement-doc/`,

  liabilityWaiverDoc: `${baseUrl}/api/v1/user/liability-doc/`,

  clientNotificationSeen: `${baseUrl}/api/v1/user/client-notification-seen/`,

  clientSignedUp: `${baseUrl}/api/v1/user/sign-up-successful/`,

  health_check: `${baseUrl}/health_check`,
};

export default URLS;
