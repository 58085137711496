import axios from 'axios';
import FundraiserActionTypes from './FundraiserActionTypes';
import * as API from '../api';
import URLS from '../../utils/constants/URLS';
import { handleErrorMessage } from '../../utils/helperFunctions';

// Define action creators for setting state values
const createAction = (type) => (payload) => ({
  type,
  payload,
});

export const initializeFundraiserState = createAction(FundraiserActionTypes.INITIALIZE_FUNDRAISER_STATE);
export const setCurrentFundraiserForm = createAction(FundraiserActionTypes.SET_CURRENT_FUNDRAISER_FORM);
export const setFundraiserCode = createAction(FundraiserActionTypes.SET_FUNDRAISER_CODE);
export const setSchoolName = createAction(FundraiserActionTypes.SET_SCHOOL_NAME);
export const setSchoolLogo = createAction(FundraiserActionTypes.SET_SCHOOL_LOGO);
export const setSelectedSponsorship = createAction(FundraiserActionTypes.SET_SELECTED_SPONSORSHIP);
export const setSponsorshipTotalAmount = createAction(FundraiserActionTypes.SET_SPONSORSHIP_TOTAL_AMOUNT);
export const setSponsorshipTotalMonths = createAction(FundraiserActionTypes.SET_SPONSORSHIP_DURATION);
export const setDonorEmail = createAction(FundraiserActionTypes.SET_DONOR_EMAIL);
export const setFetchingSchoolList = createAction(FundraiserActionTypes.SET_SCHOOL_FETCHING_FLAG);
export const setSchoolList = createAction(FundraiserActionTypes.SET_SCHOOL_LIST);
export const verifySchoolCodeStart = createAction(FundraiserActionTypes.VERIFY_SCHOOL_CODE_START);
export const verifySchoolCodeSuccess = createAction(FundraiserActionTypes.VERIFY_SCHOOL_CODE_SUCCESS);
export const verifySchoolCodeFailure = createAction(FundraiserActionTypes.VERIFY_SCHOOL_CODE_FAILURE);
export const setSelectedSchoolCode = createAction(FundraiserActionTypes.SET_SELECTED_SCHOOL_CODE);
export const setSelectedSchool = createAction(FundraiserActionTypes.SET_SELECTED_SCHOOL);
// Define action creator for verifying school code
export const verifySchoolCodeAsync = (schoolCode, setShowLocalPopup, handleFailure, setIsVerifyingSchoolCode) => {
  return (dispatch) => {
    dispatch(verifySchoolCodeStart());
    axios
      .post(URLS.validateSchoolCode, { school_code: schoolCode })
      .then((response) => {
        const { name, logo } = response.data;
        dispatch(setSchoolName(name));
        dispatch(setSchoolLogo(logo));
        dispatch(setFundraiserCode(schoolCode));
        setIsVerifyingSchoolCode(false);
        setShowLocalPopup(true);
      })
      .catch((error) => {
        const errorMessage = handleErrorMessage(error);
        dispatch(verifySchoolCodeFailure(errorMessage));
        setIsVerifyingSchoolCode(false);
        handleFailure(errorMessage || 'Invalid School Code');
      });
  };
};

// Define action creator for creating a fundraiser
export const createFundraiser = (
  data,
  confirmStripePayment,
  setIsShowLocalPopup,
  setErrorMessage,
  setProcessingPayment
) => {
  return () => {
    axios
      .post(URLS.fundraiserPayment, data)
      .then((response) => {
        confirmStripePayment(response.data);
        setProcessingPayment(false);
      })
      .catch((error) => {
        let errorMessage = 'An error occurred';
        if (error.response.data) {
          if (Array.isArray(error.response.data)) {
            [errorMessage] = error.response.data;
          } else if (typeof error.response.data === 'object') {
            errorMessage = error.response.data.error;
          }
        }
        setIsShowLocalPopup(true);
        setErrorMessage(errorMessage);
        setProcessingPayment(false);
      });
  };
};

// Define action creator for getting the school list
export const getSchoolList = (handleFailure) => {
  return (dispatch) => {
    dispatch(setFetchingSchoolList(true));
    API.get(URLS.handleSchools)
      .then((response) => {
        dispatch(setSchoolList(response.data));
        handleFailure('');
      })
      .catch((error) => {
        dispatch(setFetchingSchoolList(false));
        handleFailure(error.message || 'Can Not Get Schools');
      });
  };
};

// Define action creator for donating to a fundraiser
export const donateFundraiser = (schoolCode, handleFailure) => {
  return (dispatch) => {
    dispatch(setFetchingSchoolList(true));
    API.patch(URLS.donateFundraiser, { school_code: schoolCode })
      .then(() => {
        dispatch(getSchoolList(handleFailure));
        handleFailure('');
      })
      .catch((error) => {
        dispatch(setFetchingSchoolList(false));
        handleFailure(error.message || 'Failed To Donate');
      });
  };
};

// Define action creator for creating a school
export const createSchool = (data, handleFailure) => {
  return (dispatch) => {
    dispatch(setFetchingSchoolList(true));
    API.post(URLS.handleSchools, data)
      .then(() => {
        dispatch(getSchoolList(handleFailure));
        handleFailure('');
      })
      .catch((error) => {
        dispatch(setFetchingSchoolList(false));
        handleFailure(error.message || 'Can Not Create School');
      });
  };
};

export const getSelectedSchool = (schoolCode, handleFailure) => {
  return (dispatch) => {
    dispatch(setFetchingSchoolList(true));
    API.get(`${URLS.handleSchools}${schoolCode}`)
      .then((response) => {
        dispatch(setSelectedSchool(response.data));
        handleFailure('');
        dispatch(setFetchingSchoolList(false));
      })
      .catch(() => {
        dispatch(setFetchingSchoolList(false));
      });
  };
};

export const getNextSchoolPage = (nextPageUrl, handleFailure) => {
  return (dispatch) => {
    dispatch(setFetchingSchoolList(true));
    API.get(nextPageUrl)
      .then((response) => {
        dispatch(setSelectedSchool(response.data));
        handleFailure('');
        dispatch(setFetchingSchoolList(false));
      })
      .catch(() => {
        dispatch(setFetchingSchoolList(false));
      });
  };
};
