import { Link, useMatch } from 'react-router-dom';

import CONSTANTS from '../../utils/constants/CONSTANTS';

import './index.scss';

const TrainerHeaderLinks = ({ unreadChatCount }) => {
  return (
    <>
      <Link
        className={`${useMatch(CONSTANTS.URL_LIST.DASHBOARD) ? 'selected' : ''} option`}
        to={CONSTANTS.URL_LIST.DASHBOARD}
      >
        Dashboard
      </Link>
      <Link
        className={`${useMatch(CONSTANTS.URL_LIST.WORKOUT_BUILDER) ? 'selected' : ''} option`}
        to={CONSTANTS.URL_LIST.WORKOUT_BUILDER}
      >
        Workout Builder
      </Link>
      <Link
        className={`${useMatch(CONSTANTS.URL_LIST.CLIENTS) ? 'selected' : ''} option`}
        to={CONSTANTS.URL_LIST.CLIENTS}
      >
        Clients
      </Link>
      <Link className={`${useMatch(CONSTANTS.URL_LIST.INBOX) ? 'selected' : ''} option`} to={CONSTANTS.URL_LIST.INBOX}>
        Inbox{!unreadChatCount || <span className="chat-count-badge">{unreadChatCount}</span>}
      </Link>
      <Link
        className={`${useMatch(CONSTANTS.URL_LIST.CALENDAR) ? 'selected' : ''} option`}
        to={CONSTANTS.URL_LIST.CALENDAR}
      >
        Calendar
      </Link>
      <a className="option" target="_blank" href={CONSTANTS.trainerUrl} rel="noreferrer noopener">
        Trainer Gear
      </a>
    </>
  );
};
export default TrainerHeaderLinks;
