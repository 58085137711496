import ClientActionTypes from './ClientActionTypes';
import CONSTANTS from '../../utils/constants/CONSTANTS';
import { sortClientData } from './ClientAction';

const INITIAL_STATE = {
  allClients: {},
  allGroups: {},
  sortedClientIds: [],
  isFetchingGroups: null,
  isFetching: false,
  errorMessage: null,
  currentClient: null,
  currentGroup: null,
  showClientState: false,
  isFetchingSingle: false,
  isFetchingSingleGroup: false,
  errorMessageSingle: null,
  editAvailableSessions: false,
  updatingSessions: false,
  showGroupState: false,
  showPopup: false,
  popupData: {},
  groupId: null,
  progressData: {},
  isFetchingProgressData: false,
  showAssignTrainer: false,
  trainerInfo: {},
  newCoupon: null,
  isGeneratingCoupon: false,
  isTogglingCoupon: false,
  currentCoupon: null,
  isNewClientAdded: false,
  nextUrl: null,
};

const clientReducer = (state = INITIAL_STATE, action = { type: '' }) => {
  switch (action.type) {
    case ClientActionTypes.FETCH_ALL_CLIENTS_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: null,
      };
    case ClientActionTypes.FETCH_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        allClients: action.payload,
        isFetching: false,
        errorMessage: null,
      };
    case ClientActionTypes.SET_TOTAL_CLIENT_PAGES:
      return {
        ...state,
        totalClientPages: Math.ceil(action.payload / 30),
      };
    case ClientActionTypes.FETCH_ALL_CLIENTS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false,
      };

    case ClientActionTypes.TOGGLE_COUPON_CODE_START:
      return {
        ...state,
        isTogglingCoupon: true,
        errorMessage: null,
      };
    case ClientActionTypes.TOGGLE_COUPON_CODE_SUCCESS:
      return {
        ...state,

        isTogglingCoupon: false,
        errorMessage: null,
      };
    case ClientActionTypes.TOGGLE_COUPON_CODE_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isTogglingCoupon: false,
      };

    case ClientActionTypes.GENERATE_COUPON_START:
      return {
        ...state,
        isGeneratingCoupon: true,
        errorMessage: null,
      };
    case ClientActionTypes.GENERATE_COUPON_SUCCESS:
      return {
        ...state,
        newCoupon: action.payload,
        isGeneratingCoupon: false,
        errorMessage: null,
      };
    case ClientActionTypes.GENERATE_COUPON_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isGeneratingCoupon: false,
      };

    case ClientActionTypes.FETCH_PROGRESS_DATA_START:
      return {
        ...state,
        isFetchingProgressData: true,
        errorMessage: null,
      };
    case ClientActionTypes.FETCH_PROGRESS_DATA_SUCCESS:
      return {
        ...state,
        progressData: action.payload.progress_data.reduce((object, progressEntry) => {
          const monthIndex = new Date(progressEntry.created_at).getMonth();
          const month = CONSTANTS.monthNames[monthIndex];

          return { ...object, [month]: object[month] ? [...object[month], progressEntry] : [progressEntry] };
        }, {}),
        isFetchingProgressData: false,
        errorMessage: null,
      };
    case ClientActionTypes.FETCH_PROGRESS_DATA_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isFetchingProgressData: false,
      };
    case ClientActionTypes.FETCH_ALL_GROUPS_START:
      return {
        ...state,
        isFetchingGroups: true,
        errorMessage: null,
      };
    case ClientActionTypes.FETCH_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        allGroups: action.payload.reduce((object, group) => {
          return { ...object, [group.uuid]: group };
        }, {}),
        isFetchingGroups: false,
        errorMessage: null,
      };
    case ClientActionTypes.FETCH_ALL_GROUPS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isFetchingGroups: false,
      };
    case ClientActionTypes.FETCH_SINGLE_CLIENT_START:
      return {
        ...state,
        isFetchingSingle: true,
        errorMessageSingle: null,
      };
    case ClientActionTypes.FETCH_SINGLE_CLIENT_SUCCESS:
      return {
        ...state,
        currentClient: action.payload,
        isFetchingSingle: false,
        errorMessageSingle: null,
      };
    case ClientActionTypes.FETCH_SINGLE_CLIENT_FAILURE:
      return {
        ...state,
        errorMessageSingle: action.payload,
        isFetchingSingle: false,
        currentClient: null,
      };
    case ClientActionTypes.FETCH_SINGLE_GROUP_START:
      return {
        ...state,
        isFetchingSingleGroup: true,
        errorMessageSingle: null,
      };
    case ClientActionTypes.FETCH_SINGLE_GROUP_SUCCESS:
      return {
        ...state,
        currentGroup: action.payload,
        isFetchingSingleGroup: false,
        errorMessageSingle: null,
      };
    case ClientActionTypes.FETCH_SINGLE_GROUP_FAILURE:
      return {
        ...state,
        errorMessageSingle: action.payload,
        isFetchingSingleGroup: false,
        currentGroup: null,
      };
    case ClientActionTypes.SET_EDIT_AVAILABLE_SESSIONS:
      return {
        ...state,
        editAvailableSessions: action.payload,
      };
    case ClientActionTypes.UPDATE_AVAILABLE_SESSIONS_START:
      return {
        ...state,
        updatingSessions: true,
      };
    case ClientActionTypes.UPDATE_AVAILABLE_SESSIONS_SUCCESS:
      return {
        ...state,
        updatingSessions: false,
      };
    case ClientActionTypes.UPDATE_AVAILABLE_SESSIONS_FAILURE:
      return {
        ...state,
        updatingSessions: false,
      };
    case ClientActionTypes.SET_POPUP:
      return {
        ...state,
        showPopup: action.payload,
      };
    case ClientActionTypes.SET_POPUP_DATA:
      return {
        ...state,
        popupData: action.payload,
      };
    case ClientActionTypes.SET_SORTED_DATA: {
      const newSortedClientIDs = action.payload.reduce((object, { coupon, uuid }) => {
        return {
          ...object,
          [coupon]: object[coupon] ? [...object[coupon], uuid] : [uuid],
        };
      }, {});

      return {
        ...state,
        /** splitting clients based on coupon code into sub objects for each coupon code */
        sortedClientIds: { ...state.sortedClientIds, ...newSortedClientIDs },
      };
    }
    case ClientActionTypes.SET_CURRENT_CLIENT:
      return {
        ...state,
        currentClient: action.payload,
      };
    case ClientActionTypes.SET_CURRENT_GROUP:
      return {
        ...state,
        groupId: action.payload,
      };
    case ClientActionTypes.SET_CURRENT_COUPON:
      return {
        ...state,
        currentCoupon: action.payload,
      };
    case ClientActionTypes.SET_SHOW_CLIENT_STATE:
      return {
        ...state,
        showClientState: action.payload,
      };
    case ClientActionTypes.SET_SHOW_GROUP_STATE:
      return {
        ...state,
        showGroupState: action.payload,
      };
    case ClientActionTypes.SET_ALL_CLIENTS:
      return {
        ...state,
        allClients: action.payload,
      };
    case ClientActionTypes.SET_ALL_GROUPS:
      return {
        ...state,
        allGroups: action.payload,
      };
    case ClientActionTypes.SHOW_ASSIGN_TRAINER:
      return {
        ...state,
        showAssignTrainer: action.payload,
      };
    case ClientActionTypes.FETCH_TRAINER_INFO:
      return {
        ...state,
        trainerInfo: action.payload,
      };
    case ClientActionTypes.SET_NEW_CLIENT_ADDED:
      return {
        ...state,
        isNewClientAdded: action.payload,
      };
    case ClientActionTypes.INITIALIZE_CLIENT_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default clientReducer;
