import CustomerAgreementType from './CustomerAgreementTypes';

const initialState = {
  sessions_per_week: null,
  program_type: null,
  price: null,
  interval_type: null,
};

// eslint-disable-next-line default-param-last
const customerAgreementReducer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerAgreementType.SET_CUSTOMER_AGREEMENT_DATA:
      return {
        details: action.payload,
      };
    case CustomerAgreementType.INITIALIZE_CUSTOMER_AGREEMENT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default customerAgreementReducer;
